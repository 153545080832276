body {
  margin: 0;
  height: 100vh;
}

address {
  font-style: normal;
}

#root {
  height: 100vh;
  width: 100vw;
}
